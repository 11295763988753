import { request } from "../utils/myhttp";
export const addlists=(data)=>request({
    url:"api/dowWebsite",
    method:"post",
    data
})
export const accepts=(data)=>request({
    url:"api/acceptBdV2",
    method:"post",
    data
})
export const weblists=(data)=>request({
    url:"api/websiteContent",
    method:"post",
    data
})
